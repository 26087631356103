.Wager th {
  background-color: #86898c;
  color: #fff;
  font-weight: 700;
  font-size: 12px;
  border: 1px solid #979d9d;
  padding: 0.25rem;
}
.Wager table {
  border-collapse: collapse;
  font-size: 12px;
  width: 100%;
}
.Wager table tbody,
td {
  padding: 0.25rem;
  background-color: #fff;
}
.Wager table tbody tr td p {
  margin: 0;
}

table {
  border-collapse: collapse;
  /* box-shadow: 0px 2px 8px 0px rgba(81, 81, 81, 0.4); */
}
table td,
table th {
  /*border: 0.5px solid #323030;*/
  border: none;
}

table tr:first-child th {
  border-top: 0;
  font-size: 14px;
  font-weight: 700;
  padding: 0.5rem;
}

.Wager thead th {
  position: sticky;
  top: 80px;
  height: 19px;
}
.Wager thead .row2 th {
  position: sticky;
  top: 115.5px;
}

.Wager table tbody tr:nth-child(2n) td {
  background-color: #f6f6f6;
}

.Wager table tbody tr td {
  border-top: none;
  border-bottom: none;
}

.Wager table tbody tr:last-child td {
  border-bottom: 0.5px solid #323030;
}
/* modal styling */
.Wager-modal {
  margin: 0 auto;
  height: auto !important;
  max-height: 550px !important;
  width: 800px;
  border-radius: 4px;
  background-color: #fff;
}

.Wager-modal table tbody tr:nth-child(2n) td {
  background-color: #f6f6f6;
}

.Wager-modal table tbody tr td {
  border-top: none;
  border-bottom: none;
}

.Wager-modal table tbody tr:last-child td {
  border-bottom: 0.5px solid #323030;
}
.Wager-modal th {
  background-color: #86898c;
  color: #fff;
  font-weight: 700;
  font-size: 11px;
  border: 1px solid #979d9d;
  padding: 0.25rem;
}
.Wager-modal table {
  border-collapse: collapse;
  font-size: 12px;
  width: 100%;
}
.Wager-modal table tbody tr td p {
  margin: 0;
}
