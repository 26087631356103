  .leftside-sticky {
      position: sticky;
      top: 94px;
  }
  .leftside-name {
      font-size: 14px;
      color: #18334C;
      margin-left: 0.5rem;
  }
  .leftside-img {
      width: 18px;
      height: 18px;
  }
  .leftside-betSetting {
      font-size: 14px;
      color: #323030;
      margin: 0.3rem 0
  }
  .leftside-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  .leftside-flex2 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0.5rem 0;
  }
  .leftside-card {
      background-color: #fff;
      border-radius: 4px;
      margin: 0.3rem 0;
      margin-top: 0;
      border: 1px solid #979d9d;
      border-top: 0px;
  }
  .leftside-pending-card {
    background-color: #FFCCFF;
    border-radius: 4px;
    margin: 0.3rem 0;
    margin-top: 0;
    border: 1px solid #979d9d;
    border-top: 0px;
}
  .leftside-cardUpper {
      background-color: #86898C;
      padding: 0.25rem 0.75rem;
      max-height: 2rem;
      border-radius: 4px 4px 0 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  .leftside-cardLower {
      padding: 0.15rem 0.75rem;
      border-radius: 0 0 4px 4px;
  }
  .leftside-cardLower2 {
      padding: 0 0;
      border-radius: 0 0 4px 4px;
  }
  .leftside-heading {
      color: #fff;
      font-size: 13px;
      font-weight: bold;
  }
  .leftside-warning {
      width: 100%;
      text-align: center;
      border: 1px solid red;
      color: #E91F1F;
      font-size: 13px;
      font-weight: bold;
    }
    .leftside-login {
      color: #fff;
      font-size: 10px;
      font-weight: normal;
    }
    .leftside-balance {
      color: #1452CC;
      font-size: 12px;
      margin-top: 0.3rem;
      font-weight: bold;
      margin: 0.25rem 0 !important;
    }
    .leftside-subHeading {
      color: #323030;
      font-size: 12px;
      margin: 0rem 0 !important;
      font-weight: bold;
    }
    .leftside-subHeading2 {
      color: #86898C;
      font-size: 13px;
    }
    .leftside-subHeading2 span {
      color: #323030;
        font-size: 12px;
        margin: 0rem 0 !important;
        font-weight: bold;
    }
    .leftside-money {
      color: #1452CC;
      font-size: 13px;
      font-weight: bold;
      margin: 0 !important;
    }
    .leftside-darkHeading {
      color: #0E2336;
      font-size: 10px;
      font-weight: bold;
      margin:  0 ;
    }
    .leftside-moneyInput {
      border: 1px solid #D3D6D6;
      border-radius: 4px;
      padding: 0.25rem 0.05rem;
      width: 80px;
      color: #86898C;
      text-align: right;
    }
    .leftside-betBtn {
      background-color: #4EAD26;
      border-radius: 6px;
      color: #fff;
      font-weight: bold;
      font-size: 13px;
      border: none;
      width: 80px;
      height: 30px;
      cursor: pointer;
    }
    .leftside-cancelBtn {
      border: 1px solid #ED9297;
      border-radius: 6px;
      color: #fff;
      font-weight: bold;
      font-size: 13px;
      width: 80px;
      height: 30px;
      background-color: #ED9297;
      cursor: pointer;
    }
    .leftside-teams {
      font-size: 13px;
      color: #323030;
      margin: 0;
    }
    .leftside-desc {
      font-size: 13px;
      font-weight: bold;
      color: #18334C;
      margin: 0;
    }
    .leftside-desc2 {
      color: #86898C;
      font-size: 13px;
      margin: 0;
    }
    .leftside-handiCap {
      color: #1452CC ;
      font-size: 13px;
      font-weight: bold;
      margin: 0;
    }
    .leftside-running {
      color: #E91F1F;
      font-size: 10px;
      font-weight: bold;
      margin: 0;
    }
    .leftside-betUpper {
      padding: 0.25rem 0.5rem;
      font-size: 13px;
      color: #86898C;
      background-color:  #32A352;
      border-radius: 4px 4px 0 0 ;
      height: 38px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .leftside-betLower {
      padding: 0 1rem;
      border-radius: 0 0 4px 4px;
    }
    .leftside-betContent {
      margin: 0.5rem;
      font-weight: bolder;
      color: #fff;
    }
    .leftside-doneBtn {
      border: 1px solid #ED9297;
      border-radius: 6px;
      color: #ED9297;
      font-weight: bold;
      font-size: 13px;
      width: 80px;
      height: 30px;
      background-color: transparent;
      cursor: pointer;
    }
    .leftside-printBtn {
      background-color: #1452CC ;
      color: #fff;
      font-weight: bold;
      font-size: 13px;
      cursor: pointer;
      border: none;
      border-radius: 6px;
      width: 80px;
      height: 30px;
    }
    .leftside-spacing {
      margin-bottom: 1.25rem ;
      border-bottom: 1px solid rgba(134, 137, 140, 0.7);
      padding-bottom: 0.5rem;
    }
    .leftside-compName {
      color: #0E2336;
      font-size: 11px;
      font-weight: bold;
      margin: 0;
      padding-top: 0.25rem;
    }
    .leftside-amount {
      font-weight: bold;
      font-size: 13px;
      color: #1452CC;
      margin: 0.15rem 0;
    }
    .leftside-matchDivider {
      border-bottom: 1px solid #D3D6D6;
    }
    .leftside-matchWrapper {
      padding: 0.15rem 0.75rem;
      margin: 0.25rem 0;
    }
    .leftside-matchWrapper p {
      margin: 0;
    }
    .leftside-matchWrapper :last-child {
      padding-bottom: 0 !important;
      border-bottom-style: none;
    }

    .leftside-amountWrapper {
      padding: 0 0.75rem;
    }
    .leftside-refresh {
      cursor: pointer;
      height: 12px;
      width: 12px;
    }
    .leftside-types {
      border: 1px solid #D3D6D6;
      border-radius: 4px;
      padding: 0.05rem 0.05rem;
      color: #86898C;
      text-align: right;
      max-height: 25px;
    }
    .leftside-radio {
      color: #86898C;
      width: 12px;
      height: 12px;
      margin-right: 0.35rem !important;
      margin: 0;
    }
    .leftside-strong {
      color: #DC252F;
    }
    .leftside-betWrapper {
      margin: 0.35rem 0;
    }
    .leftside-remove {
      cursor: pointer;
    }
    .leftside-betCardLower {
    }
    .leftside-maxMin {
      color: #86898C;
      font-size: 12px;
      margin: 5px 0 0;
    }
    .leftside-flex3 {
      display: flex;
      padding: 0.15rem 0.75rem;
      justify-content: space-between;
      align-items: center;
    }
    .leftside-total {
      bottom: 0;
    }
  .leftside-lastBtn {
      font-size: 12px;
      color: #ffffff;
      text-transform: lowercase;
      line-height: 1;
      padding: 0px 8px !important;
      min-height: 0;
      min-width: 0;
  }
  .leftside-thinScroll div {
    scrollbar-width: thin;
  }

  .leftside-betUpperPending {
    padding: 0.25rem 0.5rem;
    font-size: 13px;
    color: #86898C;
    background-color: #FF0000;
    border-radius: 4px 4px 0 0;
    height: 38px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .leftside-disabledBtn {
    background-color: #86898C;
    border-radius: 6px;
    color: #fff;
    font-weight: bold;
    font-size: 13px;
    border: none;
    width: 80px;
    height: 30px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
    text-align: right;
    padding-right: 5px;
  }