.Navbar2 {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
}

.Navbar2 .logo {
  position: absolute;
  top: 5px;
  width: 77.13px;
  height: 68px;
}

.Navbar2 .navbar2-upper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 38px;
}
.Navbar2 .navbar2-upper form input {
  margin: 0 0.5rem;
  background: #f7f7f7;
  border: 1px solid #18334c;
  padding: 0.25rem 0.75rem;
}
.Navbar2 .navbar2-upper form {
  display: inline-flex;
  align-items: center;
}
.Navbar2 .navbar2-upper form input[type='submit'] {
  color: #ffffff;
  border: 1px solid #ffffff;
  background-color: #32A352;
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 0 !important;
}
.Navbar2 .navbar2-upper form button img {
  margin-right: 0.25rem;
}
.Navbar2 .navbar2-lower {
  display: flex;
  min-width: 30vw;
  margin-left: 5%;
  margin-top: 0;
}
.Navbar2 .navbar2-lower li{
  line-height: 49px;
  padding: 0 15px;
  list-style-type: none;
}
.Navbar2 .navbar2-lower li.active{
  background-color: #CCCCCC;
}
.Navbar2 .navbar2-lower li.active a{
  color: #18334C;
}
.Navbar2 .navbar2-upper li a {
  text-decoration: none;
  color: #ffffff;
  font-weight: 500;
  font-size: calc(11px + 0.25vw);
}
.Navbar2 .navbar2-lower li a {
  text-decoration: none;
  color: #ffffff;
  font-weight: 500;
  font-size: calc(11px + 0.25vw);
}
.Navbar2 .logout-wrapper {
  position: absolute;
  top: 45%;
  right: 36%;
}
