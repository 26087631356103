.OETG thead th {
  background-color: #86898c;
  color: #fff;
  font-weight: 700;
  font-size: 12px;
  /* border: 1px solid #979d9d; */
  padding: 0.25rem;
}
.OETG table {
  border-collapse: collapse;
  font-size: 12px;
  width: 100%;
}
.OETG table tbody,
td {
  padding: 0.25rem;
  background-color: transparent;
}
.OETG table tbody tr td p {
  margin: 0;
}

.OETG thead th {
  position: sticky;
  top: 90px;
  height: 19px;
  z-index: 1;
}
.OETG thead .row2 th {
  position: sticky;
  top: 115.5px;
}

/* .OETG table tbody tr:nth-child(2n) td {
  background-color: #f6f6f6;
} */

.OETG table tbody tr td {
  /* border-top: none;
  border-bottom: none; */
  font-weight: 700;
  font-size: 13px;
}

.OETG .fontNormal {
  font-weight: normal !important;
}

/* .OETG table tbody tr:last-child td {
  border-bottom: 0.5px solid #323030;
} */
/* modal styling */
.OETG-modal {
  margin: 0 auto;
  height: auto !important;
  max-height: 550px !important;
  width: 800px;
  border-radius: 4px;
  background-color: #fff;
}

.OETG-modal table tbody tr:nth-child(2n) td {
  background-color: #f6f6f6;
}

/* .OETG-modal table tbody tr td {
  border-top: none;
  border-bottom: none;
} */

/* .OETG-modal table tbody tr:last-child td {
  border-bottom: 0.5px solid #323030;
} */
.OETG-modal th {
  background-color: #86898c;
  color: #fff;
  font-weight: 700;
  font-size: 11px;
  /* border: 1px solid #979d9d; */
  padding: 0.25rem;
}
.OETG-modal table {
  border-collapse: collapse;
  font-size: 12px;
  width: 100%;
}
.OETG-modal table tbody tr td p {
  margin: 0;
}
.OETG .refreshIcon{
  position: absolute;
  right: 15px;
}
.OETG .changeBG {
  background-color: #FFF;
}
.OETG .changeBG:hover {
  background-color: #F0F5FF !important;
}
.OETG .redColor{
  color: #DC252F
}
.OETG .blue{
  color: #1452CC;
}
.OETG .pt5{
  padding-top: 5px;
}
