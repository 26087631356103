.Results th {
  background-color: #86898c;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  border: 1px solid #979d9d;
  padding: 0.25rem 0.75rem;
  box-shadow: 1px 0px 0px 0px #979d9d;
}
.Results table {
  border-collapse: collapse;
  font-size: 13px;
  width: 100%;
}
.Results table tbody,
td {
  padding: 0.25rem;
  background-color: #fff;
}
.Results table tbody tr td p {
  margin: 0;
}

table {
  border-collapse: collapse;
}
table td,
table th {
  border: .5px solid #323030;
}

table tbody {
  /* border: 1px solid grey; */
}

.Results thead th {
  position: sticky;
  top: 78px;
  height: 19px;
}
.Results thead .row2 th {
  position: sticky;
  top: 115.5px;
}

/* .Results table tbody tr:nth-child(2n) td {
  background-color: #f6f6f6;
} */

.Results table tbody tr td {
}

.Results table tbody tr:last-child td {
  border-bottom: 0.5px solid #323030;
}

.Results .show {
  display: table-row;
}
.Results .none {
  display: none;
}
