.Common-MainArea {
    width: 100%;
    display: grid;
    place-items: center;
    max-width: 1180px;
    margin: 86px auto auto !important;
    float: none !important;
  }
  .Common-MainArea2 {
    width: 80%;
    display: grid;
    place-items: center;
    max-width: 1180px;
    margin: 10px auto auto !important;
    float: none !important;
  }
  .Common-LeftPanel {
    width: 20%;
  }
  .Common-RightPanel {
    width: 80%;
    margin-top: 10px !important;
  }
  .Common-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    max-height: 18px;
  }
  .Common-flex2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .Common-img {
    height: 18px;
    width: 18px;
    margin-right: 0 0.5rem;
  }
  .Common-header {
    color: #18334C;
    font-size: 14px;
    margin-left: 0.5rem;
  }
  .Common-table {
      width: 100% !important;
  }
.Common th {
    background-color: #86898c;
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    padding: 0.5rem;
  }
  .Common table {
    border-collapse: collapse;
    font-size: 12px;
    width: 100%;
  }
  .Common table tbody,
  td {
    padding: 0.25rem;
    background-color: #fff;
  }
  .Common table tbody tr td p {
    margin: 0;
  }
  
  .Common table {
    border-collapse: collapse;
    /* box-shadow: 0px 2px 8px 0px rgba(81, 81, 81, 0.4); */
  }
  .Common table td,
  table th {
    /*border: 0.5px solid #323030;*/
    border: none;
  }
  
  .Common table tr:first-child th {
    border-top: 0;
    font-size: 14px;
    font-weight: 700;
    padding: 0.3rem;
  }
  
  .Common thead th {
    position: sticky;
    top: 80px;
    height: 19px;
  }
  .Common thead .row2 th {
    position: sticky;
    top: 115.5px;
  }
  
  .Common table tbody tr:nth-child(2n) td {
    background-color: #f6f6f6;
  }
  
  .Common  table tbody tr td {
    border-top: none;
    border-bottom: none;
  }
  
  .Common  table tbody tr:last-child td {
    border-bottom: 0.5px solid #323030;
  }
  /* modal styling */
  .Common-modal {
    margin: 0 auto;
    height: auto !important;
    max-height: 550px !important;
    width: 800px;
    border-radius: 4px;
    background-color: #fff;
  }
  
  .Common-modal table tbody tr:nth-child(2n) td {
    background-color: #f6f6f6;
  }
  
  .Common-modal table tbody tr td {
    border-top: none;
    border-bottom: none;
  }
  
  .Common-modal table tbody tr:last-child td {
    border-bottom: 0.5px solid #323030;
  }
  .Common-modal th {
    background-color: #86898c;
    color: #fff;
    font-weight: 700;
    font-size: 11px;
    border: 1px solid #979d9d;
    padding: 0.25rem;
  }
  .Common-modal table {
    border-collapse: collapse;
    font-size: 12px;
    width: 100%;
  }
  .Common-modal table tbody tr td p {
    margin: 0;
  }
  