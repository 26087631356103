.errorPage {
    background: radial-gradient(50% 50% at 50% 50%, #E91F1F 0%, #520000 100%);
    width:100%;
    height:100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.errorContent {
  margin-top: 30px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;

  text-align: center;
  color: #FFFFFF;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}