.img {
  width: 50px;
}
.center {
text-align: center;
}
.modal-popup{
  position: relative;
  padding: 20px;
  text-align: center;
}
.popup-message{
  margin-bottom: 20px;
  margin-top: 20px;
}
.popup-button{
  padding: 8px 20px;
  background: #fff;
  color: red;
  border: 1px solid #fff;
  border-radius: 5px;
  cursor: pointer;
}

.Toastify__toast-container--top-center {
  top: 30% !important;
}