.footer {
display: block;
width: 100%;
margin: 1rem 0;
}

.mobileswitch {
color: #86898C;
border: 1px solid #86898C !important;
cursor: pointer;
margin: 0 auto;
padding: 4px;
font-size: 12px;
border-radius: 4px;
background-color: #fff;
text-decoration: none;
display: flex;
align-items: center;
justify-content: center;
width: 100%;
text-align: center;
font-weight: bold;
}

.icon {
width: 1.4rem;
height: 1.4rem;
margin-right: 0.2rem;
-webkit-filter: invert(58%) sepia(5%) saturate(214%) hue-rotate(169deg) brightness(92%) contrast(88%);
filter: invert(58%) sepia(5%) saturate(214%) hue-rotate(169deg) brightness(92%) contrast(88%);
}