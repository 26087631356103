.Navbar {
  position: fixed;
  width: 100%;
  z-index: 1000;
  top: 0;
  left: 0;
}
.Navbar .logo {
  cursor: pointer;
  position: absolute;
  height: 74px;
  top: 4px;
  left: 0px;
  z-index: 1000;
}
.Navbar .navbar-upper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding-left: 110px;
  margin: 0 !important;
  list-style: none;
}
.Navbar .navbar-upper li {
  margin: 0 0.75rem;
}
.Navbar .navbar-upper a {
  display: flex;
  align-items: center;
  min-width: 75px;
  border: 1px solid #fff;
  border-radius: 4px;
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  justify-content: center;
  padding: 0.25rem 0.45rem;
  font-weight: bold;
}
.Navbar .navbar-upper li a img {
  height: 14px;
  width: 14px;
  filter: invert(1);
  margin: 0 0.25rem;
}
.Navbar .navbar-lower {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  padding-left: 100px;
}
.Navbar .navbar-lower select {
  border: none;
  background-color: transparent;
  color: #ffe248;
  font-weight: bold;
  font-size: 12px;
  text-transform: capitalize;
  margin: 0 0.5rem;
}
.Navbar .navbar-lower li a {
  text-decoration: none;
  color: #ffe248;
  font-weight: bold;
  font-size: 12px;
  margin: 0 1rem;
}
.Navbar .navBtn {
  display: flex;
}
.Navbar .navBtn li {
  display: flex;
  margin: 0 1rem;
}
.Navbar .navBtn li select {
  border: 1px solid #ffffff;
  border-radius: 4px;
  background: transparent;
  color: #fff;
  width: 45px;
  height: 22px;
}
.Navbar .navBtn li select:hover {
  background: #979d9d;
}

/* Dropdown menu */

.menu-wrapper {
  padding-left: 90px;
  /* display: flex; */
}

.Dropdown-arrow-wrapper {
  display: inline;
  padding-left: 8px;
}

.Dropdown-placeholder {
  display: inline;
  font: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
}
.Dropdown-menu {
  color: #ffe248 !important;
  border: none !important;
  background: #18334c !important;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  font: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  overflow: visible !important;
  margin-top: 15px !important;
}

.Dropdown-option {
  color: #ffe248 !important;
  background: #18334c !important;
  min-width: 150px;
}
.Dropdown-option.is-selected {
  color: #ffe248 !important;
  background: #18334c !important;
}

.dropdown-control {
  background: transparent !important;
  color: #ffe248 !important;
  border: none !important;
  top: 8px !important;
  cursor: pointer !important;
  padding: 8px 10px 8px 10px !important;
}

/*-----*/

#primary_nav_wrap ul {
  left: 0px;
  top: 0px;
  list-style: none;
  color: #ffe248;
  margin: 0;
  padding: 0;
  display: inline-table;
  width: 100%;
}

#primary_nav_wrap ul a {
  display: block;
  color: #ffe248;
  text-decoration: none;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  padding: 13px 15px;
  font: Lato;
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  transition: all 150ms linear;
}

#primary_nav_wrap ul a:hover {
  color: #ffe248;
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  transition: all 150ms linear;
}

#primary_nav_wrap ul a:active {
  color: #ffe248;
  -webkit-transition: all 50ms linear;
  -moz-transition: all 50ms linear;
  -ms-transition: all 50ms linear;
  -o-transition: all 50ms linear;
  transition: all 50ms linear;
}

#primary_nav_wrap ul li {
  position: relative;
  float: left;
  margin: 0;
  padding: 0;
}

#primary_nav_wrap ul li:hover,
#primary_nav_wrap ul li.active {
  background-color: #18334c;
}

#primary_nav_wrap ul ul {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #18334c;
  padding: 0;
}
#primary_nav_wrap ul .visible {
  display: block !important;
  position: absolute;
  top: 100%;
  left: 0;
  background: #18334c;
  padding: 0;
}
#primary_nav_wrap ul .visible {
  display: none !important;
  position: absolute;
  top: 100%;
  left: 0;
  background: #18334c;
  padding: 0;
}

#primary_nav_wrap ul ul li {
  float: none;
  width: 200px;
}

#primary_nav_wrap ul ul a {
  line-height: 120%;
  padding: 10px 15px;
  background: #18334c;
}

#primary_nav_wrap ul ul a:hover {
  background: #0f2336;
}

#primary_nav_wrap ul ul ul {
  top: 0;
  left: 100%;
}

#primary_nav_wrap ul li:hover > ul {
  display: block;
}

.hide {
  display: none !important;
}

.firstMenu{
  display:block;
  margin: 4px 0 0;
}
.firstMenu li a{
  color: #FFE248;
  text-decoration: none;
  font-size: 14px;
}
.firstMenu li.active a,.secondMenu li.active a{
  font-weight: 700;
}
.firstMenu li:last-child{
  border-right: none;
}
.firstMenu li{
  border-right: 2px solid #FFE248;
  display: inline-block;
  padding-right:10px;
  margin-right: 10px;
  list-style: none;
}
.secondMenu{
  margin: 4px 0 0;
}
.secondMenu li a{
  color: #fff;
  text-decoration: none;
  font-size: 14px;
}
.secondMenu li{
  display: inline-block;
  padding-right:10px;
  margin-right: 10px;
  list-style: none;
}