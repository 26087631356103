.MainArea {
    margin-top: 100px;
}

.MuiTab-root,
.MuiTabs-root {
    min-height: 17px !important;
}

.tab-section {
    margin-bottom: 15px;
    border-radius: 4px;
    box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.08);
}

    .tab-section .MuiTabs-root {
        border-radius: 4px 6px 0 0;
    }

    .tab-section .MuiTab-root {
        min-width: auto;
        font-weight: bold;
        font-size: 14px;
        text-transform: capitalize;
        color: #fff;
        background: #86898c;
    }

    .tab-section .MuiTab-textColorPrimary.Mui-selected {
        color: #86898C;
    }

    .tab-section .Mui-selected {
        background: #fff;
    }

.MuiTabs-indicator {
    display: none;
}

.list {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
    color: #18334c;
    font-weight: bold;
}

    .list a {
        text-decoration: none;
        color: #18334c;
    }

    .list li span.count {
        float: right;
        color: #3e95e5;
    }

.centerImage {
    border-radius: 4px;
    background: linear-gradient( 180deg, #000000 0%, rgba(255, 255, 255, 0) 50%, #000000 100% );
}

.centerImageGrid {
    position: relative;
}

.centerImageText {
    position: absolute;
    left: 5px;
    right: 5px;
    bottom: 7px;
    text-align: center;
    background: rgba(0, 0, 0, 0.6);
    padding: 8px 0;
    font-family: 'Lato';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    text-shadow: 0px 0px 3px rgba(255, 227, 83, 0.8);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.firstTable, .secondTable {
    /* box-shadow: 0px 2px 8px 0px rgba(81, 81, 81, 0.4); */
    margin-bottom: 2rem;
    border-bottom: 1px solid #979d9d;
}

.Home ::placeholder {
    font-weight: 900;
    font-size: 13px;
    color: #86898c;
    padding: 0 0.5rem;
}

.MuiCardContent-root {
    padding: 8px 16px 12px 16px !important;
}

.MuiPaper-root {
    box-shadow: none !important;
}

.float-right {
    float: right !important;
}

.banner {
  background-image: url("../img/background.jpg");
  background-position: center center;
  display: flex;
  flex-direction: column;
}

.banner-title {
  margin-top: 0.75rem;
  margin-bottom: 0.25rem;
  font-weight: bold;
  text-align: center;
}

.banner-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.banner-row div:nth-child(1) {
  width: 40%;
  padding: 0.5rem 0.75rem;
}

.banner-row div:nth-child(2) {
  width: 60%;
  text-align: center;
  padding: 0.5rem 0.75rem;
  height: 120px;
}

.qrcode {
  width: 100%;
  height: auto;
}

.banner-footer {
 background-color: #383838;
 color: #fff;
 display: flex;
 flex-direction: row;
 align-items: center;
 position: relative;
 width: 100%;
 font-size: 11px;
 min-height: 40px;
}

.banner-footer span:nth-child(1) {
  vertical-align: top;
  width: 40%;
  text-align: center;
  padding: 4px 0;
  letter-spacing: 0.12em;
  vertical-align: middle;
}

.banner-footer span:nth-child(2) {
  width: 60%;
  text-align: center;
  padding: 4px 0;
  letter-spacing: 0.12em;
}

.banner-footer span:nth-child(2) a {
  color: inherit;
  font-weight: bold;
}

.banner-footer span:nth-child(2) a:hover {
  color: #ffe248
}