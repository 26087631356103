body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Lato', 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  padding: 0;
  color: #323030 !important;
  background-color: rgba(0, 0, 0, 0);
  background-color: #f5f5f5;
}

#background {
  /*background-image: linear-gradient(to bottom, rgb(255 255 255 / 10%) , rgb(17 17 23 / 80%)), url('../img/background1.jpg');*/
  background-image: radial-gradient(
    circle,
    rgb(63, 162, 210),
    rgb(0 0 0 / 90%)
  );
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}

#background img {
  width: 100%;
  opacity: 0.8;
  background: linear-gradient(
    to bottom,
    rgba(255, 0, 0, 0) 0%,
    rgba(255, 0, 0, 0.65) 100%
  );
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: Lato, sans-serif;
  src: url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap');

  /* font-family: 'Hanuman', Lato, sans-serif !important;
  src: url('https://fonts.googleapis.com/css2?family=Hanuman:wght@400;700&family=Kantumruy:wght@300;400;700&Lato:wght@100;300;400;700&display=swap'); */
}
@font-face {
  font-family: Hanuman, sans-serif;
  src: url('https://fonts.googleapis.com/css2?family=Hanuman:wght@100;300;400;700&display=swap');
}
* {
  font-family: Lato, sans-serif;
}

.Toastify__progress-bar--dark {
  background: #dc252f !important;
}

* {
  font-family: Lato, sans-serif;
}

.lang-kh {
  font-family: 'Hanuman', serif !important;
  line-height: 1.3;
}

.lang-kh-fs-10 {
  font-size: 10px !important;
}

.lang-kh-fs-12 {
  font-size: 12px !important;
}

.d-none{
  display: none;
}

.d-table-row{
  display: table-row;
}

.fullscreen-btn{
  border:0;
  background: transparent;
  cursor: pointer;
}

.d-block{
  display: block;
}

.table-flex{
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}