.RightSide .main-row th {
  background-color: #86898c;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0.3rem;
  vertical-align: bottom;
  box-shadow: 1px 0px 0px 0px #979d9d;
}
.RightSide table {
  border-collapse: collapse;
  font-size: 13px;
  text-align: center;
  width: 100%;
}
.RightSide .row2 th {
  background-color: #86898c;
  color: #fff;
  font-weight: 700;
  font-size: 13px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0.2rem;
  vertical-align: bottom;
  box-shadow: 1px -1px 0px 0px #979d9d;
}
.RightSide table tbody,
td {
  padding: 0.1rem;
  background-color: #fff;
}

.RightSide table .trhover:hover td {
  /*background-color: #F0F5FF !important;*/
    background-color: #FFFFCC !important;
}
.RightSide table tbody tr:hover td {
  background-color: #FFFFCC !important;
}

.RightSide table tbody tr td p {
  margin: 0;
  padding: 2px;
}

.RightSide table {
  border-collapse: collapse;
}
.RightSide table td,
table th {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
/* .RightSide table td {
  font-family: 'Lato Mono';
} */

.RightSide .singleline td,
.singleline th {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.RightSide table tr:first-child th {
    border-top: 0;
}

.RightSide table tr:last-child td {
    border-bottom: 0;
}
.RightSide table tr td:first-child,
table tr th:first-child {
  border-left: 0;
}
.RightSide table tr td:last-child,
table tr th:last-child {
   border-right: 0;
} 
.singleline tbody tr td:last-child {
  border-right: 1px solid grey;
}
.RightSide thead th {
  position: sticky;
  top: 122px;
  height: 21px;
}
.Public .RightSide thead th {
  position: sticky;
  top: 78px;
  height: 21px;
}
.RightSide thead .row2 th {
  position: sticky;
  top: 146px;
}

.Public .RightSide thead .row2 th {
  position: sticky;
  top: 102px;
}

.RightSide .nobb {
  border-bottom: 0px !important;
}

.RightSide .nobt {
  border-top: 0px !important;
}
