.Ticker {
  width: 85%;
  height: 30px;
  line-height: 29px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 4px 4px;
  background: #fff !important;
  white-space: nowrap;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

@-webkit-keyframes ticker {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes ticker {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
.ticker-wrap {
  width: 100%;
  overflow: hidden;
  box-sizing: content-box;

  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: #18334c !important;
  height: 30px;
}
.ticker-wrap .ticker {
  display: inline-block;
  white-space: nowrap;
  box-sizing: content-box;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: ticker;
  animation-name: ticker;
  /* -webkit-animation-duration: 167s;
  animation-duration: 167s; */
}
.ticker-wrap .ticker:hover {
  animation-play-state: paused;
}

.ticker-wrap .ticker__item {
  display: inline-block;
  padding: 10px 2rem;
  color: #18334c !important;
  border-left: 0px !important;
  text-decoration: none;
  line-height: 14px;
}
