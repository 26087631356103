.body-modal-fix {
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: hidden;
}
.pure-modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.backdrop-overflow-hidden {
  overflow: hidden !important;
}
.pure-modal-backdrop .pure-modal {
  width: 300px;
  max-width: 100%;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  max-height: 100%;
}
.pure-modal.auto-height {
  position: static;
}
.pure-modal-backdrop.scrollable {
  overflow-y: auto;
}
.pure-modal-backdrop .panel {
  display: grid;
  grid-template-rows: repeat(3, min-content);
}
.pure-modal-backdrop:not(.scrollable) .panel {
  grid-template-rows: min-content minmax(0, 100%) min-content;
  max-height: -moz-available;
  max-height: -webkit-fill-available;
  max-height: stretch;
  height: 100%;
}
.pure-modal > * > * {
  flex: 0 0 auto;
}
.pure-modal > * > .scrollable {
  overflow-x: hidden;
  overflow-scrolling: touch;
}
@media (max-width: 480px) {
  .pure-modal-backdrop .pure-modal {
    width: 100%;
  }
}
.pure-modal .panel-body {
  background-color: #fff;
}
.pure-modal .panel-heading {
  background: #f0f0f0;
}
.pure-modal .panel-title {
  padding: 12px 45px 12px 15px;
  margin: 0;
}
.pure-modal .close {
  right: 10px;
  top: 10px;
  z-index: 1;
  background: hsla(0, 0%, 94.1%, 0.8);
  width: 30px;
  color: #8c8c8c;
  transition: color 0.1s ease-in-out;
  height: 30px;
  border-radius: 15px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}
.pure-modal .panel-heading .close:hover {
  color: #000;
}
.pure-modal .panel-body {
  padding: 15px;
}
.pure-modal .panel-footer {
  padding: 12px 45px 12px 15px;
  background: #f0f0f0;
}
.pure-modal .panel-body,
.pure-modal .panel-footer,
.pure-modal .panel-title {
  word-break: break-all;
}
.pure-modal-backdrop .additional-row,
.pure-modal-backdrop:not(.scrollable) .additional-row {
  display: grid;
  grid-template-rows: min-content minmax(0, 100%) min-content min-content;
}

.pure-modal {
  width: 920px !important;
  height: 580px !important;
  background: #ffffff !important;
  box-shadow: 2px 2px 10px 10px rgba(0, 0, 0, 0.03) !important;
  border-radius: 6px !important;
}

.pure-modal .close {
  display: none;
}

.pure-modal .panel-heading {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  background: #86898c;
  box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.03);
  font: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}

.pure-modal .panel-footer {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.done {
  width: 109px;
  height: 34px;
  border: none;
  background: #32a352;
  border-radius: 6px;
  font: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  margin-right: 20px;
  cursor: pointer;
}

.center {
  text-align: center;
}
.right {
  text-align: right;
  margin-bottom: 10px;
}
.pointer {
  cursor: pointer;
}

.header-select {
  display: flex;
}

.actionspan {
  font: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #323030;
  margin-left: 48px;
  cursor: pointer;
  background-color: #DEDEDE;
  border-radius: 4px;
  padding: 0.25rem 0.75rem;
}

.checkbox-checked:before {
  content: "\2713";
  color: #fff;
  font-size: 10px;
  position: absolute;
  left: 2px;
  top: -1px;
}

.checkbox-checked {
  background-color: #1452CC;
  margin-left: 0.5rem;
  margin-right: 0.25rem;
  height: 12px;
  width: 12px;
  border-radius: 2px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
}

.checkbox-unchecked {
 background-color: #fff;
 margin-left: 0.5rem;
 margin-right: 0.25rem;
 height: 12px;
 width: 12px;
 border-radius: 2px;
 display: inline-block;
 position: relative;
 vertical-align: middle;
 border: 1px solid #323030;
}

.cancel {
  width: 109px;
  height: 34px;
  border: 1px solid #080808;
  box-sizing: border-box;
  border-radius: 6px;
  font: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ed9297;
  background: #ffffff;
  cursor: pointer;
}

.pure-modal .panel-footer {
  background: #ffffff !important;
}

.MuiGrid-item {
  padding:5px !important;
}

.MuiGrid-container {
  font-size: 14px;
}

.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
