.Messages th {
  background-color: #86898c;
  color: #fff;
  font-weight: 700;
  font-size: 11px;
  border: 1px solid #979d9d;
  padding: 0.25rem;
}
.Messages table {
  border-collapse: collapse;
  font-size: 12px;
  width: 100%;
}
.Messages table tbody,
td {
  padding: 0.25rem;
  background-color: #fff;
}
.Messages table tbody tr td p {
  margin: 0;
}

table {
  border-collapse: collapse;
}
table td,
table th {
  border: 0.5px solid #323030;
}

table tr:first-child th {
  border-top: 0;
  box-shadow: 1px 0px 0px 0px #979d9d;
}

.Messages thead th {
  position: sticky;
  top: 78px;
  height: 19px;
}

.Messages table tbody tr:nth-child(2n) td {
  background-color: #f6f6f6;
}

.Messages table tbody tr td {
  border-top: none;
  border-bottom: none;
  font-size: 13px;
}
.Messages table tbody tr:hover td {
  background-color: #F0F5FF !important
}

.Messages table tbody tr:last-child td {
  border-bottom: 0.5px solid #323030;
}
