.Cash th {
  background-color: #86898c;
  color: #fff;
  font-weight: 700;
  font-size: 11px;
  border: 1px solid #979d9d;
  padding: 0.25rem;
}
.Cash table {
  border-collapse: collapse;
  font-size: 12px;
  width: 100%;
}
.Cash table tbody,
td {
  padding: 0.25rem;
  background-color: #fff;
}
.Cash table tbody tr td p {
  margin: 0;
}

table {
  border-collapse: collapse;
}
table td,
table th {
  border: 0.5px solid #323030;
}

table tr:first-child th {
  border-top: 0;
  font-size: 14px;
  font-weight: 700;
  padding: 0.5rem;
}

.Cash thead th {
  position: sticky;
  top: 78px;
  height: 19px;
}

.Cash table tbody tr:nth-child(2n) td {
  background-color: #f6f6f6;
}

.Cash table tbody tr td {
  border-top: none;
  border-bottom: none;
}

/* .Cash table tbody tr:last-child td {
  border-bottom: 0.5px solid #323030;
} */
